@import "flatpickr/dist/flatpickr.css";
@import "~bootstrap/scss/functions";


/*Include any default variable overrides here*/

$primary: #2c2bad;
$secondary: #e3ebf9;

$stepbar-done: $primary;
$stepbar-progress: $secondary;
$btn-previous: $secondary;
$btn: $primary;
$input-border-color: $secondary;
$input-border-color-active: $primary;

$font-family-sans-serif: 'Libre Franklin', sans-serif;/* Ex "Poppins"*/

/*la tilde ~ serve a indicare la cartella di node*/
@import "~bootstrap/scss/bootstrap";

/* tenere il file con l'underscore per importarlo senza precompilarlo, cosi viene poi compilato tutto insieme
@import "./_utilities.scss";*/

@import "./_autocomplete-02";

/* CUSTOMIZZAZIONI */

html,body{
    padding: 0px;
    margin: 0px;
    width: 100%;
    height: 100%;
}

body.signin {

    /*background-image: url(../images/welcome-kit-bg.jpg);*/

    background-image: url(../images/welcome-kit-bg-360x640.jpg);

    @media (min-width: 576px)  {
        background-image: url(../images/welcome-kit-bg-1366x768.jpg);
    }

    @media (min-width: 1401px)  {
        background-image: url(../images/welcome-kit-bg-1920x1080.jpg);
    }

    /*
    &.dest-ATT{
        background-image: url(../images/BG_WelcomeKit-ticino.png);
    }
    &.dest-DTL{
        background-image: url(../images/BG_WelcomeKit-laghi.png);
    }
    &.dest-ATL{
        background-image: url(../images/BG_WelcomeKit-novara.png);
    }
    */

    background-size: cover;
    background-position: left;

    b,a{
        color: $primary;
        text-decoration: none;
    }

    /*
        ISCRIZIONE
    */

    .the-white-box {
        background-color: #fff;
        max-width: 100%;

        @include media-breakpoint-up(lg) {
            max-width: 60%;
        }

        #istitution{
            div{
                max-height: 60px;
                img{
                    max-width: inherit;
                    max-height: inherit;
                }
            }
        }

        #signUpForm {

            .form-header {
                gap: 5px;
                text-align: center;
                font-size: 0.9em;

                .stepIndicator {
                    position: relative;
                    flex: 1;
                    padding-bottom: 30px;

                    &.active {
                        font-weight: 600;

                        &::before {
                            background-color: $stepbar-progress;
                            border: 3px solid #d5f9f6;
                            border: 3px solid $stepbar-done;
                        }
                        &::after {
                            background-color: $stepbar-progress;
                        }
                    }

                    &.finish {
                        font-weight: 600;
                        color: $stepbar-done;

                        &::before {
                            background-color: $stepbar-done;
                            border: 3px solid #b7e1dd;
                        }
                        &::after {
                            background-color: $stepbar-done;
                        }
                    }

                    &::before {
                        content: "";
                        position: absolute;
                        left: 50%;
                        bottom: 0;
                        transform: translateX(-50%);
                        z-index: 9;
                        width: 20px;
                        height: 20px;
                        background-color: #d5efed;
                        border-radius: 50%;
                        border: 3px solid #ecf5f4;
                    }

                    &::after {
                        content: "";
                        position: absolute;
                        left: 50%;
                        bottom: 8px;
                        width: 100%;
                        height: 3px;
                        background-color: #f3f3f3;
                    }

                    &:last-child:after {
                        display: none;
                    }
                }
            }

            .step {
                display: none;

                .form-label{
                    color: $primary;
                }

                .form-step-footer{
                    button{
                        border-radius: 35px;
                    }
                }
            }

        }

    }

}

/*
END BODY

##########################################

ALTRO
*/
.d-not{
    display:none;
}

.btn-with-spinner{
    > .inner-spinner{
        display: none;
    }

    &.spin{
        pointer-events: none;
        opacity: .65;

        > .inner-spinner{
            display: inline-block;
        }

        > .not-in-spinner{
            display: none;
        }
    }
}

.loader-overlay{
    display: none;
    margin: 0px;
    padding: 0px;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
    background-color: rgba(255, 255, 255, 0.4);

    &:before{
        position: absolute;
        content: "";
        display: block;
        width: 4rem;
        height: 4rem;
        border: .25em solid currentColor;
        border-right-color: transparent;
        border-radius: 50%;
        -webkit-animation: .75s linear infinite spinner-border;
        animation: .75s linear infinite spinner-border;
        /* Use bs animation*/

        margin: auto;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
    }
}